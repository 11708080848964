import { Fragment, type FC } from 'react'

import { useShowContext } from 'react-admin'

import { InvoiceStatusKeys, type InvoiceModel } from 'resources/invoices'
import { GridBreak, GridContainer, GridItemLayout, SmallSection } from 'ui'

import WoItemsCard from './WoItemsCard'
const WoItemsSection: FC = () => {
    const { record, isLoading } = useShowContext<InvoiceModel>()
    if (isLoading || !record.jobs?.length) {
        return null
    }

    const isCanceled = record.status === InvoiceStatusKeys.CANCELED

    return (
        <SmallSection>
            <GridContainer spacing={{ xs: 3, sm: 6 }}>
                {record.jobs.map((job) => (
                    <Fragment key={job.id}>
                        <GridItemLayout>
                            <WoItemsCard
                                job={job}
                                isCanceled={isCanceled}
                            />
                        </GridItemLayout>
                        <GridBreak />
                    </Fragment>
                ))}
            </GridContainer>
        </SmallSection>
    )
}

export default WoItemsSection
