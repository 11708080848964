import { useRecordContext } from 'react-admin'

import { useDistanceLabel } from 'components'
import { useCreateResourcePath } from 'core'
import { Link } from 'lib'
import { SliderWidget, SliderWidgetSkeleton } from 'pages/Dashboard/components/WidgetBase/styled'
import { type UnitModel } from 'resources/units'
import { woResource } from 'resources/workOrders'
import { Typography } from 'ui'

import { Label } from './components'
import { getSinceLastRepairData } from './utils'

const SinceLastServiceTrackWidget = () => {
    const record = useRecordContext<UnitModel>()
    const distanceLabel = useDistanceLabel({ textCase: 'capital', plural: true })

    const createPath = useCreateResourcePath()
    if (!record) {
        return <SliderWidgetSkeleton />
    }
    const woPath = createPath({
        resource: woResource.resource,
        type: 'edit',
        id: record.lastCompletedWorkOrder.id,
    })
    const sinceLastRepairData = getSinceLastRepairData({
        lastRepairMeters: record.lastCompletedWorkOrderMeters,
        lastMeters: record.lastMeters,
        lastWorkOrder: record.lastCompletedWorkOrder,
    })

    return (
        <SliderWidget
            headerProps={{
                title: `Days/${distanceLabel} Since Last Service`,
                labelElement: <Label {...sinceLastRepairData} />,
                clarification: (
                    <Typography
                        marginTop="14px"
                        color="primary.main"
                        component={Link}
                        to={woPath}
                        variant="componentButtonSmall"
                    >
                        SEE LAST WORK ORDER
                    </Typography>
                ),
            }}
        />
    )
}
export default SinceLastServiceTrackWidget
