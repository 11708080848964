import { type FC } from 'react'

import { integerNonNegativeSpacedMaskResolver } from 'components'
import { formatDate } from 'lib'
import { getMeterTypeAdornment, type MeterModel } from 'resources/units'

import { type PMLastMetersContextResult, usePMLastMetersContext } from './PMLastMetersContext'

interface PMLastMeterHelperTextProps {
    meterType: MeterModel['type']
    meters?: PMLastMetersContextResult['meters']
    meter?: MeterModel
}

const PMLastMeterHelperText: FC<PMLastMeterHelperTextProps> = ({ meters, meterType, meter }) => {
    const metersContext = usePMLastMetersContext()
    const finalMeters = meters || metersContext?.meters
    const finalMeter = meter || finalMeters?.[meterType]
    if (!finalMeter) {
        return null
    }
    return (
        <>
            Last reading {integerNonNegativeSpacedMaskResolver(finalMeter.value)}{' '}
            {getMeterTypeAdornment(meterType)} •{' '}
            {formatDate(finalMeter.timestamp, ({ shortenedDateTime }) => shortenedDateTime)}
        </>
    )
}

export default PMLastMeterHelperText
