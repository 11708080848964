import { useRecordContext } from 'react-admin'

import { ViewHeader } from 'components'
import { useIsVenderWo, type WorkOrderModel } from 'resources/workOrders'
import { Section } from 'ui'

import VendorSectionHeader from './VendorSectionHeader'
import WoAddVendorCard from './WoAddVendorCard'
import WorkOrderVendorCard from './WorkOrderVendorCard'

const VendorSection = () => {
    const record = useRecordContext<WorkOrderModel>()
    const isVendorWorkOrder = useIsVenderWo()

    if (!record || record.salesOrderData) {
        return null
    }

    const isStatusCancel = record.status === 'CANCELED'
    return (
        <Section>
            {isStatusCancel ? (
                <ViewHeader
                    title="Vendor"
                    loading={!record}
                />
            ) : (
                <VendorSectionHeader />
            )}
            {isVendorWorkOrder ? (
                <WorkOrderVendorCard
                    data={record}
                    canceled={isStatusCancel}
                />
            ) : (
                <WoAddVendorCard />
            )}
        </Section>
    )
}

export default VendorSection
