import { TextInput } from 'react-admin'

import Icons from 'assets/icons'
import {
    type ActionChildren,
    api,
    basePermissions,
    maxLengthValidationText,
    renderOnPermission,
    requiredValidation,
    updatedMessage,
    useConfirm,
    useNotify,
} from 'core'
import { invoicesResource, type InvoiceModel } from 'resources/invoices'
import { Alert } from 'ui'
import { pathJoin } from 'utils'

import SendInvoiceDrawer from './SendInvoiceDrawer'

const descriptionValidation = [maxLengthValidationText, requiredValidation]

interface ActionProps {
    children: ActionChildren
    record: InvoiceModel
    disabled?: boolean
}

export const ReopenAction = renderOnPermission(({ children, record, disabled }: ActionProps) => {
    const confirm = useConfirm()
    const notify = useNotify()
    return children({
        disabled,
        Icon: Icons.Undo,
        onClick: () => {
            confirm({
                title: 'Reopen Invoice',
                closeOnError: false,
                content:
                    'Reopening this invoice will revert it to an editable state, allowing you to modify the details of all line items.',
                onConfirm: async () => {
                    await api
                        .post(pathJoin(invoicesResource.resource, record.id, 'reopen'))
                        .then(() => {
                            notify(updatedMessage)
                        })
                },
                confirmButtonProps: {
                    children: 'Reopen',
                    startIcon: <Icons.Undo />,
                },
                awaitOnConfirm: true,
            })
        },
        title: 'Reopen',
    })
}, basePermissions.update)

export const ResetAction = renderOnPermission(({ children, record, disabled }: ActionProps) => {
    const confirm = useConfirm()
    const notify = useNotify()

    return children({
        disabled,
        Icon: Icons.RefreshOutlined,
        onClick: () => {
            confirm({
                title: 'Are you sure you want to reset this Invoice?',
                closeOnError: false,
                content:
                    'The invoice will be restored to its original form as it was generated from the work order.',
                onConfirm: () => {
                    api.post(pathJoin(invoicesResource.resource, record.id, 'reset')).then(() => {
                        notify(updatedMessage)
                    })
                },
                confirmButtonProps: {
                    children: 'Reset',
                    startIcon: <Icons.RefreshOutlined />,
                },
                awaitOnConfirm: true,
            })
        },
        title: 'Reset',
    })
}, basePermissions.update)

export const CancelAction = renderOnPermission(({ children, record, disabled }: ActionProps) => {
    const confirm = useConfirm()
    const notify = useNotify()
    return children({
        disabled,
        Icon: Icons.CancelOutlined,
        onClick: () => {
            confirm({
                title: 'Are you sure you want to cancel this Invoice?',
                closeOnError: false,
                content: (
                    <>
                        <Alert
                            severity="warning"
                            sx={{ mb: '22px' }}
                        >
                            Cancelling a invoice is an irreversible action
                        </Alert>
                        <TextInput
                            source="reason"
                            validate={descriptionValidation}
                            label="Reason for Cancellation"
                            multiline
                            rows={3}
                        />
                    </>
                ),
                onConfirm: async ({ formValues }) => {
                    api.post(pathJoin(invoicesResource.resource, record.id, 'cancel'), {
                        reason: formValues.reason,
                    }).then(() => {
                        notify(updatedMessage)
                    })
                },
                confirmButtonProps: {
                    children: 'Confirm',
                },
                awaitOnConfirm: true,
            })
        },
        title: 'Cancel',
    })
}, basePermissions.update)

export const SendInvoiceAction = ({
    record,
    children,
    disabled,
    title = 'Send Invoice',
}: ActionProps & { record: InvoiceModel; title?: string }) => {
    return (
        <SendInvoiceDrawer invoice={record}>
            {(open) =>
                children({
                    disabled,
                    title,
                    Icon: Icons.SendOutlined,
                    onClick: open,
                })
            }
        </SendInvoiceDrawer>
    )
}
