import { useRecordContext } from 'react-admin'

import api from 'core/api'
import { useQuery } from 'lib'
import { SliderWidget, SliderWidgetSkeleton } from 'pages/Dashboard/components/WidgetBase/styled'
import { createReportResource } from 'resources/reports'

import { UnitPMWidgetBars, UnitPmWidgetLabel } from './components'
import { type PMWidgetData } from './types'

const UnitPMWidget = () => {
    const record = useRecordContext()

    const { data } = useQuery<PMWidgetData>(['reports/pm-schedules', record.id], async () => {
        const data: PMWidgetData = await api.get(createReportResource('pm-schedules'), {
            unit: record.id,
        })
        return data
    })

    if (!data) {
        return <SliderWidgetSkeleton />
    }
    const mutatedData = data

    return (
        <SliderWidget
            headerProps={{
                title: 'PM Intervals',
                labelElement: <UnitPmWidgetLabel data={mutatedData} />,
                chart: <UnitPMWidgetBars data={mutatedData} />,
            }}
        />
    )
}
export default UnitPMWidget
