import { type ReactElement, useState, type ReactNode } from 'react'

import Icons from 'assets/icons'
import { useDebounce } from 'hooks'
import { globalClassNames, useMediaQuery } from 'lib'
import {
    Box,
    ClickAwayListener,
    type BoxProps,
    Stack,
    BoxContainer,
    Typography,
    Skeleton,
    Tooltip,
} from 'ui'

import { useWidgetContext } from '../GridLayout'

import { WidgetBodySeparator } from './components'
import { WidgetHeaderContainer } from './styled/HeaderComponents'
import { checkWidgetDimensions } from './useCollapseWidget'
export interface WidgetHeaderProps {
    title: string | ReactElement
    label?: string | number | ReactNode | null
    labelElement?: ReactElement
    clarification?: ReactNode
    chart?: ReactNode
    tooltipText?: string | null
    containerProps?: BoxProps
    separator?: ReactNode
}
export const WidgetHeaderSkeleton = () => {
    const dimension = useWidgetContext()
    return (
        <WidgetHeaderContainer
            sx={{
                alignItems: 'center',
            }}
            className="WidgetHeaderSkeleton"
            gap="12px"
        >
            <Stack
                spacing="12px"
                width="100%"
            >
                <Skeleton height="8px" />
                <Skeleton height="8px" />
            </Stack>
            {checkWidgetDimensions(dimension, ['lg', 'mdX']) ? (
                <Skeleton
                    sx={{
                        width: '80px',
                        height: '80px',
                        flexShrink: '0',
                    }}
                    variant="circular"
                />
            ) : null}
        </WidgetHeaderContainer>
    )
}
export const WidgetHeader = ({
    title,
    label,
    labelElement,
    clarification,
    chart,
    tooltipText,
    separator,
    containerProps,
}: WidgetHeaderProps) => {
    const matches = useMediaQuery((theme) => theme.breakpoints.down('md'))

    const dimension = useWidgetContext()

    const [openTooltip, setOpenTooltip] = useState(false)
    const handleDebounceClose = useDebounce(() => setOpenTooltip((p) => false), { time: 5000 })

    const handleMobileClickAway = () => {
        if (matches) {
            setOpenTooltip((p) => false)
        }
    }
    const handleMobileTooltipClick = () => {
        if (matches) {
            setOpenTooltip((p) => !p)
            handleDebounceClose()
        }
    }

    return (
        <WidgetHeaderContainer
            className="WidgetHeader"
            {...containerProps}
        >
            <Stack
                alignItems="flex-start"
                overflow="hidden"
            >
                <Typography
                    variant="chartTitle"
                    paddingBottom={dimension === 'sm' ? '17px' : '24px'}
                    paddingRight={dimension === 'sm' ? '10px' : '0px'}
                    color="text.secondary"
                    className="WidgetBaseTitle"
                >
                    {title}
                </Typography>
                <BoxContainer
                    gap="4px"
                    maxWidth="100%"
                >
                    <Typography
                        variant="h5"
                        fontSize={checkWidgetDimensions(dimension, ['sm', 'mdY']) ? '20px' : '24px'}
                        className={labelElement ? '' : globalClassNames.ellipsis}
                    >
                        {labelElement || label || 'No Data'}
                    </Typography>
                    {tooltipText && (
                        <ClickAwayListener onClickAway={handleMobileClickAway}>
                            <BoxContainer>
                                <Tooltip
                                    title={tooltipText}
                                    open={openTooltip}
                                    onClick={handleMobileTooltipClick}
                                    onMouseOver={matches ? null : () => setOpenTooltip(true)}
                                    onMouseLeave={matches ? null : () => setOpenTooltip(false)}
                                    enterTouchDelay={0}
                                    leaveTouchDelay={5000}
                                >
                                    <BoxContainer
                                        width="12px"
                                        height="12px"
                                        justifyContent="center"
                                        padding="9px"
                                    >
                                        <Icons.InfoOutlined
                                            sx={(theme) => ({
                                                width: '12px',
                                                height: '12px',
                                                color: openTooltip
                                                    ? theme.palette.text.primary
                                                    : theme.palette.action.disabled,
                                            })}
                                        />
                                    </BoxContainer>
                                </Tooltip>
                            </BoxContainer>
                        </ClickAwayListener>
                    )}
                </BoxContainer>
                {clarification ? <Stack alignItems="flex-start">{clarification}</Stack> : null}
                <Box />
            </Stack>
            {chart && checkWidgetDimensions(dimension, ['lg', 'mdX']) ? (
                <Box
                    position="relative"
                    zIndex="3"
                    mt="5px"
                >
                    {chart}
                </Box>
            ) : null}
            <WidgetBodySeparator title={separator} />
        </WidgetHeaderContainer>
    )
}
