import { type FC } from 'react'

import { useShowContext } from 'react-admin'

import Icons from 'assets/icons'
import images from 'assets/images'
import {
    AddButton,
    CollapsibleInfoCard,
    InfoCardRowBase,
    InfoCardTitle,
    NoResultsCard,
} from 'components'
import { InvoiceStatusKeys, type InvoiceModel } from 'resources/invoices'
import { ItemsTotal } from 'resources/lineItems'
import { Box, Button, GridContainerColumns, GridItemLayout } from 'ui'

import { ItemsAddMenu, ItemsContent } from '../../components'

const DirectlyAddedCard: FC = () => {
    const { record } = useShowContext<InvoiceModel>()

    if (!record?.directlyAdded) {
        return null
    }

    const isCanceled = record.status === InvoiceStatusKeys.CANCELED

    if (isCanceled && !record.directlyAdded.length) {
        return <EmptyCard />
    }

    const disabled = record.status === InvoiceStatusKeys.CLOSED || isCanceled

    return (
        <GridContainerColumns>
            <GridItemLayout>
                <CollapsibleInfoCard
                    defaultOpen
                    disableActiveState
                >
                    {isCanceled ? (
                        <InfoCardTitle>Directly Added</InfoCardTitle>
                    ) : (
                        <InfoCardRowBase label={<InfoCardTitle>Directly Added</InfoCardTitle>}>
                            <ItemsAddMenu
                                renderToggler={(open) => (
                                    <AddButton
                                        onClick={disabled ? null : open}
                                        disabled={disabled}
                                    />
                                )}
                            />
                        </InfoCardRowBase>
                    )}
                    {record.directlyAdded.length ? (
                        <ItemsContent invoiceItems={record.directlyAdded} />
                    ) : (
                        <>
                            <Box py="20px">
                                <ItemsAddMenu
                                    renderToggler={(open) => (
                                        <Button
                                            size="large"
                                            startIcon={<Icons.Add />}
                                            onClick={open}
                                            disabled={disabled}
                                        >
                                            add line item
                                        </Button>
                                    )}
                                />
                            </Box>
                            <ItemsTotal total={0} />
                        </>
                    )}
                </CollapsibleInfoCard>
            </GridItemLayout>
        </GridContainerColumns>
    )
}

export default DirectlyAddedCard

const EmptyCard = () => {
    return (
        <NoResultsCard
            title="No Items"
            direction="row"
            height="178px"
            imageSrc={images.noPayments}
            imageProps={{
                alt: 'No Items',
                width: {
                    xs: '122px',
                    sm: '107px',
                },
                mr: {
                    xs: 0,
                    sm: '16px',
                },
            }}
        />
    )
}
